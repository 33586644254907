import React, {useState} from 'react';
import Image from '../components/nomads-image';
import Layout from '../components/layout';
import {ReactComponent as Bottle} from '../assets/images/pages/beers/black.svg';
import {BEER_INFO, COLORS} from "../common/consts";
import {useTranslation} from "gatsby-plugin-react-i18next";
import '../assets/styles/pages/_beers.scss';
import {graphql} from "gatsby";
import SEO from "../components/seo";

const BeerCard = ({name, description, abv, pairings, image}) => {
    return (
        <div className="beer-card-wrapper">
            <div className="text-wrapper">
                <h3>{name}</h3>
                <p>{description}</p>
                <span>ABV: {abv}</span>
                <span>Pairings: {pairings}</span>
            </div>
            <div className="image-wrapper">
                <Image img={image}/>
            </div>
        </div>
    )
}

const Beers = () => {
    const [modalActive, setModalActive] = useState(false);
    const [beer, setBeer] = useState(null);
    // const [color, setBeer] = useState(null);

    const {t} = useTranslation();

    const closeModal = () => setModalActive(false);

    const handleClick = e => {
        const beer = e.target.dataset['beer'];
        setBeer(beer)
    }

    const genBeer = () => {
        const activeBeer = BEER_INFO[beer];

        return (
            <div className="beer-info">
                <div dangerouslySetInnerHTML={{__html: t(`${activeBeer.description}.description`)}}/>
                <dl>
                    <dt>ABV:</dt><dd>{activeBeer.abv}</dd>
                    <dt>Pairings:</dt><dd>{t(`${activeBeer.description}.pairings`)}</dd>
                </dl>
            </div>
        )
    }

    return (
        <Layout pageID={'beers'}>
            <SEO title="Beers" />
            <section className="top">
                <h1>{t("beers")}</h1>
            </section>
            <section className="content">
                <aside className="side-menu">
                    <ul>
                        {
                            Object.keys(BEER_INFO).map((key, i) => (<li onClick={handleClick} key={`beer-${i}`} data-beer={key}>{BEER_INFO[key].name}</li>))
                        }
                    </ul>
                </aside>
                <article>
                    <div className="description">
                        {
                            beer
                                ?
                                genBeer()
                                :
                                <div className="beer-info">
                                    <h2>{t("philosophy")}</h2>
                                    <p>{t("phil_text")}</p>
                                </div>
                        }
                    </div>
                    <Bottle fill={COLORS[beer] || '#3c2219'} style={{transition: 'all 0.2s linear', height: '100%'}}/>
                </article>
            </section>
        </Layout>
    )
}

export default Beers;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "beers"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
